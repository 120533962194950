<!--
 * @Descripttion: 全景列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-11 10:25:49
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-07-18 15:09:39
-->
<template>
    <div class="scenariosList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">全景列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="screen-item">
                        <el-select clearable v-model="funClassif" filterable
                            @change="funClassifChange" placeholder="请选择功能分类">
                            <el-option
                                v-for="item in funOptions"
                                :key="item.functionTypeId"
                                :label="item.functionTypeName"
                                :value="item.functionTypeId">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="screen-item">
                        <el-select clearable v-model="styleClassif" filterable
                            @change="styleClassifChange" placeholder="请选择风格分类">
                            <el-option
                                v-for="item in styleOptions"
                                :key="item.styleTypeId"
                                :label="item.styleTypeName"
                                :value="item.styleTypeId">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="isAddScenarios = true" type="primary">添加全景</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="sceneId" label="ID" width="80"></el-table-column>
                    <el-table-column align="center" prop="sceneName" label="全景名称" ></el-table-column>
                    <el-table-column align="center" prop="content" label="全景图片">
                        <template slot-scope="scope">
                            <img :src="scope.row.sceneImageFileId" height="30" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="fun.functionTypeName" label="功能分类" ></el-table-column>
                    <el-table-column align="center" prop="style.styleTypeName" label="风格分类" ></el-table-column>
                    <el-table-column align="center" prop="style.styleTypeName" label="风格分类(多选)" >
                        
                        <template slot-scope="scope">
                            <div v-for="(tag,index) in scope.row.style_ids_attr" :key="index">
                                {{tag.styleTypeName}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="user.mobile" label="专属商家" >
                        <template slot-scope="scope">
                            <div v-if="scope.row.shop == null">通用</div>
                            <div v-else>{{scope.row.shop.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="is_hot" label="热度值" >
                        <template #header>
                            <el-tooltip class="item" effect="dark" content="根据热度值排序" placement="top">
                                <div class="sort-wrap" @click="changeShow('is_hot')">
                                    <span>热度值</span>
                                    <i class="iconfont el-icon-caret-bottom"></i>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="sceneVersion" label="版本号" ></el-table-column>
                    <el-table-column align="center" label="操作" width="90">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加全景素材</div>
            <div v-else class="title">编辑全景素材</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rulesScenarios" ref="scenariosForm" label-width="130px" class="demo-ruleForm">
                    <el-form-item label="全景名称" prop="sceneName">
                        <el-input v-model="scenariosForm.sceneName" placeholder="请输入全景名称"></el-input>
                    </el-form-item>
                    <el-form-item label="版本号" prop="sceneVersion">
                        <el-input v-model="scenariosForm.sceneVersion" placeholder="请输入版本号"></el-input>
                    </el-form-item>
                    <el-form-item label="选择品牌" prop="ep_id">
                        <el-select clearable v-model="ep_id" filterable
                            placeholder="请选择品牌" @change="changeEp">
                            <el-option
                                v-for="item in epOptions"
                                :key="item.ep_id"
                                :label="item.name"
                                :value="item.ep_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择商家" prop="shop_id">
                        <el-select clearable v-model="shop_id" filterable
                            placeholder="请选择商家(先选品牌)" @change="changeShop">
                            <el-option
                                v-for="item in shopOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择小区" prop="srd_id">
                        <el-select clearable v-model="srd_id" filterable
                            placeholder="请选择小区(先选商家)" @change="changeSrd">
                            <el-option
                                v-for="item in srdOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择户型" prop="sht_id">
                        <el-select filterable clearable v-model="sht_id" placeholder="请选择户型(先选小区)">
                            <el-option
                                v-for="item in shtOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="功能分类" prop="functionId">
                        <el-select filterable clearable v-model="scenariosForm.functionId" placeholder="请选择功能分类">
                            <el-option
                                v-for="item in funOptions"
                                :key="item.functionTypeId"
                                :label="item.functionTypeName"
                                :value="item.functionTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="风格分类" prop="styleId">
                        <el-select filterable clearable v-model="scenariosForm.styleId" placeholder="请选择风格分类">
                            <el-option
                                v-for="item in styleOptions"
                                :key="item.styleTypeId"
                                :label="item.styleTypeName"
                                :value="item.styleTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="风格分类(多选)" prop="style_ids">
                        <el-select multiple filterable clearable v-model="scenariosForm.style_ids" placeholder="请选择风格分类">
                            <el-option
                                v-for="item in styleOptions"
                                :key="item.styleTypeId"
                                :label="item.styleTypeName"
                                :value="item.styleTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="边/腰线" prop="line">
                        <el-checkbox v-model="scenariosForm.sideLin" label="边线" border></el-checkbox>
                        <el-checkbox v-model="scenariosForm.waistLin" label="腰线" border></el-checkbox>
                    </el-form-item>
                    <el-form-item label="全景图片" prop="bgImage">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="scenariosForm.bgImage.length<1" listType="picture-card"
                                v-model="scenariosForm.bgImage">
                            </aliyun-upload>
                            <div v-if="scenariosForm.bgImage.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="scenariosForm.bgImage" :src="scenariosForm.bgImage[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="文件上传" prop="iosFile">
                        <div class="upload-wrap-content">
                            <div class="upload-wrap">
                                <unity-upload :btnName="scenariosForm.iosFile !=''?'已上传IOS文件':'IOS文件上传'" 
                                    unityType="sceneIosModelFileId"
                                    v-model="scenariosForm.iosFile">
                                </unity-upload>
                            </div>
                            <div class="upload-wrap">
                                <unity-upload :btnName="scenariosForm.androidFile !=''?'已上传Android文件':'Android文件上传'" 
                                    unityType="sceneAndroidModelFileId"
                                    v-model="scenariosForm.androidFile">
                                </unity-upload>
                            </div>
                            <div class="upload-wrap">
                                <unity-upload :btnName="scenariosForm.winFile !=''?'已上传Win文件':'Win文件上传'" 
                                    unityType="sceneWinModelFileId"
                                    v-model="scenariosForm.winFile">
                                </unity-upload>
                            </div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitEditForm">编辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import axios from "axios";
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
import UnityUpload from "@/components/upload/UnityUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components:{
        ElImageViewer,
        AliyunUpload,
        UnityUpload
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'scenariosList',
            funClassif: '',
            styleClassif: '',
            keyword: '',
            funOptions: [],
            styleOptions: [],
            epOptions: [],
            shopOptions: [],
            srdOptions: [],
            shtOptions: [],
            currentPage: 1,
            total: 0,
            tableData: [],
            loading: false,
            addLoading: false,
            isAddScenarios: false,
            btnLoading: false,
            ep_id: '',
            shop_id: '通用',
            srd_id: '通用',
            sht_id: '通用',
            editId: -1,
            sort_by: '',
            scenariosForm: {
                sceneName: '',
                sceneVersion: 1,
                functionId: '',
                styleId: '',
                style_ids: [],
                sideLin: '',
                waistLin: '',
                bgImage: [],
                iosFile: '',
                androidFile: '',
                winFile: ''
            },
            rulesScenarios: {
                sceneName: [
                   { required: true, message: '请输入全景名称', trigger: 'blur' },
                ],
                functionId: [
                    { required: true, message: '请选择功能分类', trigger: 'change' },
                ],
                styleId: [
                    { required: true, message: '请选择风格分类', trigger: 'change' },
                ],
                bgImage: [
                    { required: true, message: '请上传全景图片', trigger: 'blur' },
                ]
            },
        }
    },
    mounted () {
        this.getSceneList();
        this.getEpList();
        this.getfunctionList();
        this.getstyleList();
    },
    watch:{
        ep_id(newVal,oldVal){
            if(newVal != ''){
                common.connect("/customerservicev1/shop/allShopConditions",{ep_id: newVal},(res)=>{
                    this.shopOptions = res.data;
                });
            }else{
                this.shop_id = '通用';
                this.srd_id = '通用';
                this.sht_id = '通用';
            }
        },
        shop_id(newVal,oldVal){
            if(newVal !=''){
                common.connect("/customerservicev1/scene/residentialConditions",{shop_id: newVal},(res)=>{
                    this.srdOptions = res.data;
                });
            }
        },
        srd_id(newVal,oldVal){
            common.connect("/customerservicev1/scene/houseTypeConditions",{srd_id: newVal},(res)=>{
                this.shtOptions = res.data;
            });
        },
        isAddScenarios(newVal,oldVal){
            if(!newVal){
                this.ep_id = '';
                this.shop_id = '通用';
                this.srd_id = '通用';
                this.sht_id = '通用';
                this.editId = -1;
                let scenariosForm = {
                    sceneName: '',
                    sceneVersion: 1,
                    functionId: '',
                    styleId: '',
                    sideLin: '',
                    waistLin: '',
                    bgImage: [],
                    style_ids: [],
                    iosFile: '',
                    androidFile: '',
                    winFile: ''
                }
                this.scenariosForm = scenariosForm
            }
        }
    },
    methods: {
        // 点击排序
        changeShow(item){
            this.sort_by = item;
            this.currentPage = 1;
            this.getSceneList();
        },
        // 选择品牌获取商家
        changeEp(){
            this.shop_id = '通用';
            this.srd_id = '通用';
            this.sht_id = '通用'
        },
        // 选择商家获取小区
        changeShop(){
            this.srd_id = '通用';
            this.sht_id = '通用'
        },
        // 选择小区获取户型
        changeSrd(){
            this.sht_id = '通用'
        },
        // 获取品牌数据
        getEpList(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                this.epOptions = res.data;
            });
        },
        // 获取功能下拉
        getfunctionList(){
            common.connect("/customerservicev1/scene/functionConditions",{},(res)=>{
                this.funOptions = res.data;
            });  
        },
        // 获取风格下拉
        getstyleList(){
            common.connect("/customerservicev1/scene/styleConditions",{},(res)=>{
                this.styleOptions = res.data;
            });  
        },
        // 获取数据列表
        getSceneList(){
            let params = {
                page: this.currentPage,
                functionId: this.funClassif,
                styleId: this.styleClassif,
                keyword: this.keyword,
                sort_by: this.sort_by
            }
            this.loading = true;
            common.connect('/customerservicev1/scene/index',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 创建
        submitForm(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    if(this.shop_id == '通用' || this.shop_id == ''){
                        this.shop_id = -1
                    }
                    if(this.srd_id == '通用' || this.srd_id == ''){
                        this.srd_id = -1
                    }
                    if(this.sht_id == '通用' || this.sht_id == ''){
                        this.sht_id = -1
                    }
                    let params = {
                        sceneName: this.scenariosForm.sceneName,
                        sceneVersion: this.scenariosForm.sceneVersion,
                        shop_id: this.shop_id,
                        srd_id: this.srd_id,
                        sht_id: this.sht_id,
                        ep_id: this.ep_id,
                        sideLin: this.scenariosForm.sideLin?'1':'0',
                        waistLin: this.scenariosForm.waistLin?'1':'0',
                        functionId: this.scenariosForm.functionId,
                        styleId: this.scenariosForm.styleId,
                        style_ids: JSON.stringify(this.scenariosForm.style_ids),
                        sceneImageFileId: this.scenariosForm.bgImage.length>0?this.scenariosForm.bgImage[0]:'',
                        sceneIosModelFileId: this.scenariosForm.iosFile,
                        sceneAndroidModelFileId: this.scenariosForm.androidFile,
                        sceneWinModelFileId: this.scenariosForm.winFile,
                    }
                    this.btnLoading = true;
                    console.log(params);
                    common.connect('/customerservicev1/scene/store',params,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneList();
                            this.$message.success(res.msg);
                            this.isAddScenarios = false;
                            this.$refs.scenariosForm.resetFields();
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 更新
        submitEditForm(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    if(this.shop_id == '通用' || this.shop_id == ''){
                        this.shop_id = -1
                    }
                    if(this.srd_id == '通用' || this.srd_id == ''){
                        this.srd_id = -1
                    }
                    if(this.sht_id == '通用' || this.sht_id == ''){
                        this.sht_id = -1
                    }
                    let params = {
                        sceneId: this.editId,
                        sceneName: this.scenariosForm.sceneName,
                        sceneVersion: this.scenariosForm.sceneVersion,
                        shop_id: this.shop_id,
                        srd_id: this.srd_id,
                        sht_id: this.sht_id,
                        ep_id: this.ep_id,
                        sideLin: this.scenariosForm.sideLin?'1':'0',
                        waistLin: this.scenariosForm.waistLin?'1':'0',
                        functionId: this.scenariosForm.functionId,
                        styleId: this.scenariosForm.styleId,
                        style_ids: JSON.stringify(this.scenariosForm.style_ids),
                        sceneImageFileId: this.scenariosForm.bgImage.length>0?this.scenariosForm.bgImage[0]:'',
                        sceneIosModelFileId: this.scenariosForm.iosFile,
                        sceneAndroidModelFileId: this.scenariosForm.androidFile,
                        sceneWinModelFileId: this.scenariosForm.winFile,
                    }
                    this.btnLoading = true;
                    common.connect('/customerservicev1/scene/update',params,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneList();
                            this.$message.success(res.msg);
                            this.isAddScenarios = false;
                            this.$refs.scenariosForm.resetFields();
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑
        clickEdit(row){
            this.editId = row.sceneId
            common.connect('/customerservicev1/scene/edit',{sceneId: row.sceneId},(res)=>{
                if(res.isSuccess == 1){
                    this.isAddScenarios = true;
                    this.ep_id = res.data.ep_id;
                    this.scenariosForm.sceneName = res.data.sceneName;
                    this.scenariosForm.sceneVersion = res.data.sceneVersion;
                    if( res.data.shop_id == '-1' || res.data.shop_id == 0){
                        res.data.shop_id = '通用'
                    }
                    if( res.data.srd_id == '-1' || res.data.srd_id == 0){
                        res.data.srd_id = '通用'
                    }
                    if( res.data.sht_id == '-1' || res.data.sht_id == 0){
                        res.data.sht_id = '通用'
                    }
                    this.shop_id = res.data.shop_id;
                    this.srd_id = res.data.srd_id;
                    this.sht_id = res.data.sht_id;
                    this.scenariosForm.sideLin = res.data.sideLin == 1?true:false;
                    this.scenariosForm.waistLin = res.data.waistLin == 1?true:false;
                    this.scenariosForm.functionId = res.data.functionId;
                    this.scenariosForm.style_ids = JSON.parse(res.data.style_ids);
                    this.scenariosForm.styleId = res.data.styleId;
                    this.scenariosForm.bgImage[0] = res.data.sceneImageFileId;
                    this.scenariosForm.iosFile = res.data.sceneIosModelFileId;
                    this.scenariosForm.androidFile = res.data.sceneAndroidModelFileId;
                    this.scenariosForm.winFile = res.data.sceneWinModelFileId;
                }
            })
        },
        //删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该全景, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/scene/delete',{sceneId: row.sceneId},(res)=>{
                    if(res.isSuccess == 1){
                        this.getSceneList();
                        this.$message.success(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 关键词搜索
        clickSearch(){
            this.currentPage = 1;
            this.getSceneList();
        },
        // 专属商家筛选
        esChange(){
            this.currentPage = 1;
            this.getSceneList();
        },
        // 风格分类筛选
        styleClassifChange(){
            this.currentPage = 1;
            this.getSceneList();
        },
        // 功能分类筛选
        funClassifChange(){
            this.currentPage = 1;
            this.getSceneList();
        },
        delImageList(){
            this.scenariosForm.bgImage.splice(0,1)
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getSceneList();
        },
    },
}
</script>

<style lang='scss'>
.scenariosList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .screen-item{
                    margin-right: 15px;
                    margin-bottom: 20px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 20px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .table-wrap{
            .sort-wrap{
                cursor: pointer;
            }
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 580px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                margin-right: 10px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-checkbox-group{
        .el-checkbox{
            margin-right: 0 !important;
        }
    }
}
</style>
