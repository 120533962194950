<!--
 * @Descripttion: 全景上传
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-03 18:01:55
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-04 16:34:51
-->
<template>
    <div class="unity-upload">
        <el-upload
            ref="upload"
            class="upload-demo"
            action
            :limit="10"
            :show-file-list="false"
            :before-upload="initUploadData"
            :http-request="uploadRequest"
            accept=".unity3d"
            >
            <el-button  type="primary">{{btnName}}</el-button>
        </el-upload>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import axios from "axios";
let _loading = {};
export default {
    model:{
        prop: 'beenData',
        event: 'unityData'
    },
    props:{
        btnName:{ //上传按钮名称
            type:String,
            default:"+ 上传"
        },
        unityType:{
            type:String,
            default: ""
        },
        beenData:{ //使用v-model 相互绑定的映射对象.
            type:String,
            default(){
                return ''
            }
        },
    },
    data () {
        return {

        }
    },
    mounted () {
        
    },
    methods: {
        initUploadData(file){
            _loading = this.$loading({text:"上传中"});
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension = testmsg === "unity3d";
            if (!extension) {
                this.$message({
                    message: "上传文件只能是.unity3d格式!",
                    type: "warning",
                });
                _loading.close();
            }
            return extension;
        },
        uploadRequest(file){
            let filename = file.file.name
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let formData = new FormData();
            formData.append(this.unityType,file.file);
            formData.append('type',this.unityType);
            let url = common.apiServer+'/customerservicev1/scene/uploadUnityOss'
            axios({
                url: url,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': authorization,
                },
                onUploadProgress: progressEvent => {
                    let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
                    _loading.text = '上传 ' +filename+" "+ complete
                }
            }).then((res)=> {
                if(res.data.isSuccess == 1){
                    this.$message.success(res.data.msg);
                    this.$emit('unityData',res.data.data)
                    _loading.close();
                }else{
                    this.$message.error(res.data.msg);
                    _loading.close();
                }
            })
        },
    },
}
</script>

<style scoped lang='scss'>
.HIDEUPBTN .el-upload--picture-card{
  display: none;
}
.aliyun-upload{
  line-height: 20px;
  font-size: 13px;

}
.aliyun-upload .el-upload-list__item-thumbnail{
    object-fit: contain;
}
.contanerFitImageView  .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    object-fit: contain;
}
.contanerCoverImageView .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    object-fit: cover;
}
.btn{
    margin-right: 10px;
}
</style>
